import {
  useContext,
  useEffect,
  useState
} from 'react';
import { useRouter } from "next/router";

import Image from 'next/image';

import {
  Button,
  Typography,
  TextField,
  useMediaQuery
} from '@mui/material';

import ReCAPTCHA from 'react-google-recaptcha';

import { RequestNextApi } from '../../api/Request';
import ChangePasswordModal from '../../components/ChangePassword/ChangePasswordModal';
import { ProgressContext } from '../../components/Context/progressContext';
import { UserContext } from '../../components/Context/userContext';
import { GlobalsContext } from '../../components/Context/globalsContext'
import CreateAccountModal from '../../components/Registration/CreateAccountModal';
import {
  getCookie,
  removeCookie,
  setCookie
} from '../../utils/cookieHandle';
import { ShowNotification } from '../../utils/notification/snackbarUtils';
import { SidebarContext } from '../../components/Context/sidebarContext';

const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;

export default function LoginPage() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);
  const [ip, setIp] = useState();

  const router = useRouter();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { update: updateSidebarContext } = useContext(SidebarContext);
  const { update: updateProgress } = useContext(ProgressContext);
  const { update: updateUser, state: userContext } = useContext(UserContext);
  const { update: updateGlobals, state: globalsContext } = useContext(GlobalsContext);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  useEffect(() => {
    removeCookie('jwt-token');
    removeCookie('jwt-refresh-token');
    updateUser({ user: [] });
    updateGlobals({ globals: [] })
    getIp();
  }, []);

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    // Set the IP address to the constant `ip`
    setIp(data.ip);
    setCookie('remote_addr', data.ip);
  };

  const handleRoute = () => {
    const { query } = router;

    if (query.returnUrl) {
      router.push(query.returnUrl);
    } else {
      router.push('/members/home');
    }
  };

  const onChangeCaptcha = (value) => {
    if (value) {
      setRecaptchaSuccess(true);
    } else {
      setRecaptchaSuccess(false);
    }
  };

  const handleLogin = async () => {
    if (password === '' || email === '') {
      ShowNotification.error('Fields cannot be empty!');
    } else {
      updateProgress({ isLoading: true });
      try {
        const result = await RequestNextApi.post('/login',
          {
            username: email,
            password: password,
            remote_addr: getCookie('remote_addr')
          }
        );
        if (result.data.success) {
          var sidebarCollapse = isMobile;
            updateSidebarContext({
              sidebarCollapse,
            });
          setCookie('jwt-token', result.data.jwtToken);
          setCookie('jwt-refresh-token', result.data.refreshToken, { expires: 3 });
          const userInfo = await RequestNextApi.post('/user/user-fetch2',
            {
              jwtToken: result.data.jwtToken
            }
          );
          const globalInfo = await RequestNextApi.post('/global-variables',
            {
              jwtToken: result.data.jwtToken
            }
          );

          if (globalInfo.data.success) {
            updateGlobals({ globals: globalInfo.data.globals })
          }
          if (userInfo.data.success) {
            updateUser({ user: userInfo.data.user })
            if (userInfo.data.user.forcePasswordReset === true) {
              setOpenChangePassword(true);
            } else {
              handleRoute()
            }
          }
        } else {
          ShowNotification.error('Login failed!');
        }
      } catch (error) {
        ShowNotification.error('Login failed!');
      }
      updateProgress({ isLoading: false });
    }
  };

  useEffect(() => {
    console.log("globalsContext: ", globalsContext)
    console.log("userContext: ", userContext)
  }, [globalsContext, userContext])

  return (
    <>
      <div className="min-h-full flex flex-col justify-center items-center h-screen py-12 sm:px-6 lg:px-8">
        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
            <div className="flex flex-row justify-center items-center w-full pb-10 ">
              <Image src="/static/images/kingdomadvisors.png" alt="Kingdom Advisor Logo" width={200} height={81} />
            </div>
            <div className="space-y-6">
              <div className="mt-1">
                <TextField
                  type="text"
                  label="Email"
                  color="third"
                  size="small"
                  fullWidth
                  id="email"
                  name="email"
                  placeholder=""
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  variant="outlined"
                />
              </div>
              <div className="mt-1">
                <TextField
                  type="password"
                  label="Password"
                  color="third"
                  size="small"
                  fullWidth
                  id="Password"
                  name="Password"
                  variant="outlined"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-center">
                <div className="text-sm">
                  <a href="/login-link" rel="noreferrer" target="_blank" className="fort-medium text-secondary-500 m-0 underline">
                    Forgot your password? Login by Email
                  </a>
                </div>
              </div>
              <div className='flex justify-center'>
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={onChangeCaptcha}
                />
              </div>
              <div>
                <Button
                  className='bg-secondary-500 hover:bg-secondary-500 w-full'
                  type="submit"
                  variant="contained"
                  disabled={!recaptchaSuccess}
                  onClick={handleLogin}
                >
                  <Typography className="text-sm font-medium text-white p-1">Sign in</Typography>
                </Button>
              </div>
              <div className='flex flex-row items-center justify-center'>
                <CreateAccountModal
                  updateProgress={updateProgress}
                  View={"login"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        defaultEmail={email}
        open={openChangePassword}
        setOpen={setOpenChangePassword}
        handler={handleRoute}
      />
    </>
  )
}

LoginPage.getLayout = page => <>{page}</>;
LoginPage.displayName = 'Login';
LoginPage.isPublic = true;
